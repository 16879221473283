import { registerApplication, start } from 'single-spa';
import {
    constructApplications,
    constructLayoutEngine,
    constructRoutes,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import './main.css';
import keycloak from './config/keycloak';

declare global {
    interface Window {
        keycloak: import('keycloak-js').KeycloakInstance;
    }
}

keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then((authenticated) => {
        if (authenticated) {
            window.keycloak = keycloak;

            const layoutData = {
                props: {
                    isDarkMode: true,
                    project: 'sama',
                },
                loaders: {},
            };

            const routes = constructRoutes(microfrontendLayout, layoutData);
            const applications = constructApplications({
                routes,
                loadApp({ name }) {
                    return System.import(name);
                },
            });
            const layoutEngine = constructLayoutEngine({
                routes,
                applications,
            });

            applications.forEach(registerApplication);
            layoutEngine.activate();
            start();
        } else {
            window.location.reload();
        }
    });
